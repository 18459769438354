import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getColorFromStatus, Status } from 'shared/utils/Status';
import { getSite } from 'store/slices/siteSlice';
import { useNavigate } from 'react-router-dom';
import { setSelectedDevice } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { getDeviceStatus } from '../Utils/SiteUtil';
import { RootState } from 'store';
import { useCallback, useMemo, useState } from 'react';
import {
  useCreateConfigFileMutation,
  useFetchCustomSoundsMutation,
  useHandleGatewayCommandMutation
} from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useSiteContext } from '../SiteContext/SiteContext';

export interface RowElem {
  id: string;
  MacAddress: string;
  StationNumber: string;
  StationName: string;
  ModelNumber: string | null;
  FirmwareStatus: string;
  Status: string;
  ConfigFileUrl: string;
}

/**
 * Interface for the props to SiteGrid component
 * @interface SiteGridProps
 * @property {function} handleConfigFileUpload - Function to handle the config file upload
 */
export interface SiteGridProps {
  handleConfigFileUpload: (device: RowElem) => Promise<Status.Synced | Status.Error | undefined>;
}

/**
 * Site Grid Component
 * @param {SiteGridProps} props
 * @returns {JSX.Element}
 */
export const SiteGrid = ({ handleConfigFileUpload }: SiteGridProps) => {
  const {
    rows,
    setRows,
    setGwOnlineStatus,
    setSyncStatus,
    setErrorMessage,
    setSuccessMessage,
    setShowAlert,
    setSyncDialogTitle,
    setSyncDialogContent,
    setIsSyncDialogOpen
  } = useSiteContext();

  const { t } = useTranslation();
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAllowedTo } = usePermission();
  const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
  const [createConfigFile] = useCreateConfigFileMutation();
  const [fetchCustomSoundsById] = useFetchCustomSoundsMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const hostname = window.location.hostname;
  const isProductionAccount = !['preprod', 'beta', 'localhost'].some((substring) => hostname.includes(substring));
  const hasEditPermission = isAllowedTo('site:edit', site.siteInfo.publicId, PermissionsContextType.SITE);
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);

  // Hide the TestConfigUrl for production accounts
  const columnVisibilityModel = useMemo(() => {
    if (isProductionAccount) {
      return {
        ConfigFileUrl: false
      };
    }
    return {
      ConfigFileUrl: true
    };
  }, [isProductionAccount]);

  const handleNavigateToFirmwareUpdate = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/maintenance`);
  };

  const handleNavigateToStationInfo = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/stationinfo`);
  };

  const handleNavigateToNetworkSettings = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/networkinfo`);
  };

  const handleNavigateToUnit = () => {
    navigate(`/site/${site.siteInfo.publicId}/units`);
  };

  const handleNavigateToCallList = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/callsettings`);
  };

  const handleNavigateToAddressBook = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/addressbook`);
  };

  /** Function to handle the upload sound files */
  const handleUploadSoundFiles = async (rowData: RowElem) => {
    const device = devices[rowData.id];

    // get config file url
    try {
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: true }));
      const response = await createConfigFile({
        sitePublicId: site.siteInfo.publicId,
        devicePublicId: rowData.id
      });
      if (response?.data) {
        rowData.ConfigFileUrl = response.data;

        //generate payload for sound files
        const sentPayload = await generateSoundFilePayload(rowData, 'sendCommand');
        if (!sentPayload) {
          throw new Error('Failed to upload sound files');
        }

        /*******   check to see if the device have initial synced, if not, call sync function first       ********/
        const isInitialSyncComplete = device?.lastSyncedOn !== null;
        if (!isInitialSyncComplete) {
          const syncResponse = await handleConfigFileUpload(rowData);
          if (syncResponse !== Status.Synced) {
            throw new Error('Failed to sync with the device');
          }
        }

        //send payload to gateway
        const ioTResponse = await handleGatewayCommand(sentPayload).unwrap();
        if (ioTResponse?.statusCode === 200) {
          const fetchPayload = await generateSoundFilePayload(rowData, 'fetchResult');
          let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          if (fetchResponse?.statusCode.includes('206')) {
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          }
          if (!fetchResponse?.payload[0].statusCode.includes('200')) {
            throw new Error('Failed to upload sound files');
          }
        } else {
          throw new Error('Failed to upload sound files');
        }
      }
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false }));
      setShowAlert(true);
      setSuccessMessage(t('Success'));
    } catch (error) {
      setErrorMessage(t('Failed_to_upload_sound_file'));
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false }));
    }
  };

  /** Function to handle the clickable cell */
  const clickableCell = (params: GridCellParams) => {
    const rowData = params.row as RowElem;
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleNavigateToStationInfo(rowData);
        }}
      >
        {params.value as string}
      </div>
    );
  };

  /***************************                 Sound File                 ****************************/
  const generateSoundFilePayload = async (rowData: RowElem, command: string) => {
    const device = devices[rowData.id];
    const isGatewayFirstSync = gateway?.lastSyncedOn === null;

    if (!device) {
      return;
    }
    const params = {
      device: {
        devicePublicId: rowData.id
      }
    };

    let customSoundList;
    try {
      customSoundList = await fetchCustomSoundsById(params).unwrap();
      if (customSoundList === null) {
        return;
      }
    } catch (error) {
      console.error('Failed to fetch custom sounds:', error);
      return;
    }
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const isDeviceFirstSync = device?.lastSyncedOn === null;

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };

    const deviceInfo = {
      deviceIpAddress: device?.networkSettings?.ipV4Address,
      deviceMacAddress: device?.basicInfo?.macAddress,
      deviceType: rowData?.ModelNumber,
      deviceId: isDeviceFirstSync ? 'admin' : device?.basicInfo.adminId,
      devicePassword: isDeviceFirstSync ? 'admin' : device?.basicInfo.adminPass,
      deviceConfigFileUrl: rowData.ConfigFileUrl,
      deviceFileName: `config-${device.publicId}.txt`,
      customSoundList: customSoundList
    };
    if (command === 'fetchResult') {
      return fetchGatewayCommand('fetchResult', gwCommand.SOUND_UPLOAD, gatewayInfo, deviceInfo, null);
    } else {
      return fetchGatewayCommand('sendCommand', gwCommand.SOUND_UPLOAD, gatewayInfo, deviceInfo, null);
    }
  };

  // ************************************ Grid Columns ************************************
  const columns: GridColDef[] = [
    {
      field: 'MacAddress',
      headerName: t('MAC_Address'),
      width: 150,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      cellClassName: 'custom-column',
      renderCell: clickableCell
    },
    {
      field: 'ModelNumber',
      headerName: t('Model_Number'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      cellClassName: 'custom-column',
      renderCell: clickableCell
    },
    {
      field: 'StationName',
      headerName: t('Station_Name'),
      width: 130,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: clickableCell
    },
    {
      field: 'StationNumber',
      headerName: t('Station_Number'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: clickableCell
    },
    {
      field: 'Status',
      headerName: t('Station_Status'),
      width: 220,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Chip
            sx={{
              ...styles.tableButtons,
              borderColor: !hasEditPermission ? 'grey' : 'default',
              pointerEvents: !hasEditPermission ? 'none' : 'auto'
            }}
            label={
              <Typography
                variant="body2"
                sx={{
                  color: !hasEditPermission ? 'grey' : 'default'
                }}
              >
                <span
                  style={{ cursor: params.value !== Status.StandBy ? 'pointer' : 'default' }}
                  onClick={() => {
                    if (hasEditPermission) {
                      if (params.value === Status.NeedsStationNumber) {
                        const rowData = params.row;
                        handleNavigateToStationInfo(rowData);
                      }
                      if (params.value === Status.NeedsIPAddress || params.value === Status.NeedsAssociation) {
                        const rowData = params.row;
                        handleNavigateToNetworkSettings(rowData);
                      }
                      if (params.value === Status.NeedsUnit) {
                        handleNavigateToUnit();
                      }
                      if (params.value === Status.NeedsCallList) {
                        const rowData = params.row;
                        handleNavigateToCallList(rowData);
                      }
                      if (params.value === Status.NeedsAddressBook) {
                        const rowData = params.row;
                        handleNavigateToAddressBook(rowData);
                      }
                      if (params.value === Status.NeedsSync) {
                        const rowData = params.row;
                        params.row.Status = Status.Waiting;
                        handleSyncOneStationButton(rowData).then();
                      }
                      if (params.value === Status.Error) {
                        const rowData = params.row;
                        params.row.Status = getDeviceStatus(devices[rowData.id]);
                      }
                    }
                  }}
                >
                  {params.value}
                </span>
              </Typography>
            }
            color={getColorFromStatus(params.value)}
            size="medium"
            variant="outlined"
          />
        </Box>
      )
    },
    {
      field: 'FirmwareStatus',
      headerName: t('Firmware_Status'),
      width: 160,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Chip
              sx={{
                ...styles.tableButtons,
                borderColor: !hasEditPermission ? 'grey' : 'default',
                pointerEvents: !hasEditPermission ? 'none' : 'auto'
              }}
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: !hasEditPermission ? 'grey' : 'default'
                  }}
                >
                  <span
                    style={{ cursor: params.value === Status.NeedsUpdate ? 'pointer' : 'default' }}
                    onClick={() => {
                      if (hasEditPermission) {
                        const rowData = params.row as RowElem;
                        handleNavigateToFirmwareUpdate(rowData);
                      }
                    }}
                  >
                    {params.value}
                  </span>
                </Typography>
              }
              color={getColorFromStatus(params.value)}
              size="medium"
              variant="outlined"
            />
          </Box>
        );
      }
    },
    {
      field: 'soundFile',
      headerName: t('Sync_Sound_File'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        const excludedModelNumbers = [
          'IXGW-TGW',
          'IXGW-GW',
          'IXW-MA',
          'IXW-MAA',
          'IXGW-LC',
          'IXGW-LC-RY20',
          'IXG-2C7',
          'IXG-2C7-L'
        ];
        const rowData = params.row as RowElem;
        if (rowData.ModelNumber && excludedModelNumbers.includes(rowData.ModelNumber)) {
          return null;
        }
        return (
          <Button
            variant="outlined"
            size="small"
            disabled={!hasEditPermission || loadingRows[rowData.id]}
            onClick={() => {
              handleUploadSoundFiles(rowData);
            }}
          >
            {loadingRows[rowData.id] ? <CircularProgress size={24} /> : 'Sync Sound'}
          </Button>
        );
      }
    },
    {
      field: 'ConfigFileUrl',
      headerName: t('Test_Config_URL'),
      width: 0,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell(params) {
        if (params.row.ConfigFileUrl === '-') {
          return params.row.ConfigFileUrl;
        } else {
          return (
            <a href={params.row.ConfigFileUrl} target="_blank">
              {t('Config_File_Link')}
            </a>
          );
        }
      }
    }
  ];
  // ************************************ Grid Columns ************************************

  /**
   * Function to handle the sync button click
   * Fetch list of config files
   * set the gateway's row as busy
   */
  const handleSyncOneStationButton = useCallback(
    async (device: RowElem) => {
      const dialogTitle = t('Gateway_DeviceSync_Title');
      const dialogContent = t('Gateway_DeviceSync_Content');
      setSyncDialogTitle(dialogTitle);
      setSyncDialogContent(dialogContent);
      setIsSyncDialogOpen(true);
      setGwOnlineStatus(Status.Busy);
      setSyncStatus(Status.Waiting);
      try {
        const response = await createConfigFile({
          sitePublicId: site.siteInfo.publicId,
          devicePublicId: device.id
        });
        if ('data' in response) {
          const newRows = JSON.parse(JSON.stringify(rows));
          for (const row of newRows) {
            if (row.id === device.id) {
              row.ConfigFileUrl = response.data;
              row.Status = Status.Syncing;
              setRows(newRows);
              const gatewaySyncResponse = await handleConfigFileUpload(row);
              if (gatewaySyncResponse !== void 0) {
                row.Status = gatewaySyncResponse;
                setRows(newRows);
              }
            }
          }
          setSyncStatus(Status.Synced);
        } else {
          throw new Error('Failed to create config files');
        }
      } catch (error) {
        setSyncStatus(Status.Error);
      }
      setIsSyncDialogOpen(false);
      setGwOnlineStatus(Status.Online);
    },
    [rows, site, handleConfigFileUpload]
  );

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        loading={rows.length === 0}
      />
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  tableHeaders: {
    fontWeight: 'bold',
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  tableButtons: {
    height: 'auto',
    borderRadius: '100px',
    padding: '4px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    marginTop: '10px'
  }
};
