import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardMedia, Divider, Grid, Typography } from '@mui/material';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getDeviceCategoryFromDeviceTypeModel, getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { RootState } from 'store';
import {
  AddressBookOutlineIcon,
  CallSettingsOutlineIcon,
  EntranceStationsOutlineIcon,
  GatewaySettingsIcon,
  HomeIcon,
  InputOutputOutlineIcon,
  IntegrationsOutlineIcon,
  LiftControlOutlineIcon,
  MaintenanceOutlineIcon,
  NetworkInfoLANPortOutlineIcon,
  PadlockOutlineIcon,
  PagingOutlineIcon,
  RemoteManagementIcon,
  SiteInfoOutlineIcon,
  SoundSettingsOutlineIcon,
  TransferOutlineIcon
} from 'shared/icons/icons';
import { useTranslation } from 'react-i18next';

import { ENABLE_LIFT_CONTROL } from 'shared/constants/config';

function DeviceSideBar(props: any) {
  const theme = useTheme();
  const location = useLocation();
  const siteName = useSelector((state: RootState) => state.site.siteInfo.siteName);
  const locationArray = location.pathname.split('/');
  const stationId = locationArray[4];
  const publicId = locationArray[2];
  const devicePublicId = useParams().deviceid ?? '';
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const selectedDevice = deviceList[devicePublicId]?.basicInfo;

  const { t } = useTranslation();

  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    selectedDevice.deviceModel,
    selectedDevice.deviceType
  )}.png`;

  const handleAllDevicesClick = () => {
    // TODO: dispatch action to clear selected device
  };

  interface DeviceMenu {
    name: string;
    icon: ReactNode;
  }

  const allMenuItems = {
    StationInfo: { name: t('Station_Info'), icon: <SiteInfoOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    NetworkInfo: {
      name: t('Network_Info'),
      icon: <NetworkInfoLANPortOutlineIcon color="primary" viewBox={'0 0 26 26'} />
    },
    SecuritySettings: {
      name: t('Security_Settings'),
      icon: <PadlockOutlineIcon color="primary" viewBox={'0 0 26 26'} />
    },
    CallSettings: { name: t('Call_Settings'), icon: <CallSettingsOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    AddressBook: { name: t('Address_Book'), icon: <AddressBookOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    EntranceSettings: {
      name: t('Entrance_Settings'),
      icon: <EntranceStationsOutlineIcon color="primary" viewBox={'0 0 26 26'} />
    },
    PhoneSettings: {
      name: t('AdvancedSettings_Tab_PhoneSettings'),
      icon: <GatewaySettingsIcon color="primary" viewBox="0 0 26 26" />
    },
    SoundSettings: {
      name: t('Sound_Settings'),
      icon: <SoundSettingsOutlineIcon color="primary" viewBox={'0 0 26 26'} />
    },
    InputOutput: { name: t('Input_Output'), icon: <InputOutputOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    Integrations: { name: t('Integrations'), icon: <IntegrationsOutlineIcon color="primary" viewBox={'0 0 26 26 '} /> },
    Maintenance: { name: t('Maintenance'), icon: <MaintenanceOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    Transfer: { name: t('Transfer'), icon: <TransferOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    Paging: { name: t('Paging'), icon: <PagingOutlineIcon color="primary" viewBox={'0 0 26 26'} /> },
    ...(ENABLE_LIFT_CONTROL && {
      LiftControl: { name: t('Lift_Control'), icon: <LiftControlOutlineIcon color="primary" viewBox={'0 0 26 26'} /> }
    }),
    DoorRelease: { name: t('Door_Release'), icon: <AddressBookOutlineIcon color="primary" viewBox={'0 0 26 26'} /> }
  };

  let menuItems: DeviceMenu[] = [];
  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        //allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.AudioDoorStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        //allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.TenantStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.EntranceStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.EntranceSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        //allMenuItems.Paging,
        //allMenuItems.Integrations,
        allMenuItems.Transfer,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.SubMasterStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.DoorRelease,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Transfer,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.IOAdaptor:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.InputOutput,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.GatewayAdaptor:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        //allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      if (selectedDevice.deviceModel === 14) {
        const securitySettingsIndex = menuItems.indexOf(allMenuItems.SecuritySettings);
        if (securitySettingsIndex !== -1) {
          menuItems.splice(securitySettingsIndex + 1, 0, allMenuItems.PhoneSettings);
        } else {
          menuItems.push(allMenuItems.PhoneSettings);
        }
      }
      break;
    case DeviceCategory.LiftControl:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        //allMenuItems.Integrations,
        allMenuItems.LiftControl,
        allMenuItems.Maintenance
      ];
      break;
  }

  return (
    <Sidebar
      style={styles.sidebar as any}
      breakPoint="md"
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
    >
      <Link style={styles.siteLink} to={`/site/${publicId}/siteinfo`}>
        <Box sx={styles.siteBadge}>
          <Typography sx={styles.siteName}>{siteName}</Typography>
          <Grid container spacing={1} direction="column" justifyContent="center">
            <Grid item xs={12}>
              <Box sx={styles.device}>
                <CardMedia component="img" image={imageUrl} alt={'Device Image'} sx={styles.deviceImage} />
                <Typography variant="body1" sx={styles.deviceInfo}>
                  {selectedDevice.stationName}
                </Typography>
                <Typography variant="body1" sx={styles.deviceInfo}>
                  {selectedDevice.stationNumber}
                </Typography>
                <Typography variant="body1" sx={styles.deviceInfo}>
                  {getDeviceModelNumberFromModelType(selectedDevice.deviceModel, selectedDevice.deviceType)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Link>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        {menuItems.map((item, index) => (
          // Fixing "Do not use Array index in keys" ESLint error
          <MenuItem
            key={item.name + index.toString()}
            active={
              location.pathname ===
              `/site/${publicId}/devices/${stationId}/${item.name.toLowerCase().replace(/ /g, '')}`
            }
            component={
              <Link to={`/site/${publicId}/devices/${stationId}/${item.name.toLowerCase().replace(/ /g, '')}`} />
            }
            icon={item.icon}
          >
            <Typography variant="body2">{item.name}</Typography>
          </MenuItem>
        ))}
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to={`/site/${publicId}/`} />}
          onClick={handleAllDevicesClick}
          icon={<SiteInfoOutlineIcon color="primary" viewBox={'0 0 26 26'} />}
        >
          <Typography variant="body2">{t('Return_to_Site')}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        style={{ width: '100%' }}
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem
          active={location.pathname === '/'}
          component={<Link to="/" />}
          icon={<HomeIcon color={'primary'} viewBox={'0 0 26 26'} />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Home')}
          </Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<RemoteManagementIcon color={'primary'} viewBox={'0 0 26 26'} />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Remote_Management')}
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%)',
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: 1000
  },
  siteLink: {
    textDecoration: 'none'
  },
  siteBadge: {
    border: '2px solid #D7D7D7',
    boxShadow: '.5px .5px .5px .5px rgba(0,0,0,0.2)',
    borderRadius: '2%',
    margin: '.5rem',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  siteName: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1.5rem',
    fontWeight: '700',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    margin: '.5rem 0'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0'
  },
  device: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#6D6E71',
    fontSize: '12px'
  },
  deviceImage: {
    objectFit: 'contain',
    height: '60px',
    width: '60px',
    marginBottom: '8px'
  },
  deviceInfo: {
    fontSize: '1rem',
    textAlign: 'center'
  }
};

export default DeviceSideBar;
